import React, { useContext } from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { useInView } from 'react-intersection-observer'
import { SiteLink } from './SiteLink'
import Price from './Price'
import { StoreContext } from '../contexts'
import useRootMargin from '../hooks/window/useRootMargin'
import useHeaderColor from '../hooks/useHeaderColor'
import { mobileVW, desktopVW, color, desktopBreakpoint } from '../styles'

const FourProdsGrid = ({ products, collection }) => {
  const { currency } = useContext(StoreContext)
  const rootMargin = useRootMargin()
  const [refFourImgGrid, inViewFourImgsGrid] = useInView({ rootMargin })

  useHeaderColor(
    inViewFourImgsGrid,
    {
      left: color.yellow,
      center: color.yellow,
      right: color.yellow
    },
    {
      left: color.black,
      center: color.yellow,
      right: color.black
    }
  )

  return (
    <>
      <Grid collection={collection} ref={refFourImgGrid}>
        {products.map(
          ({ slug, productImages, title, shopifyProduct }, index) => (
            <GridItem key={index} slug={slug} bgColor={index}>
              <Image
                alt={productImages[0].foregroundImage
                  ? productImages[0].foregroundImage.title
                  : null}
                fluid={productImages[0].foregroundImage
                  ? productImages[0].foregroundImage.fluid
                  : null}
              />
              <InfoWrapper>
                <Name bgColor={index}>{title}</Name>
                <PriceWrapper bgColor={index}>
                  <Price shopifyProduct={shopifyProduct} label />
                </PriceWrapper>
              </InfoWrapper>
            </GridItem>
          )
        )}
      </Grid>
    </>
  )
}

const Grid = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: ${mobileVW(16)};
  grid-row-gap: ${({ collection }) =>
    collection ? mobileVW(50) : mobileVW(56)}};
  padding: ${({ collection }) =>
    collection ? ` 0 0 ${mobileVW(42)}` : `0 ${mobileVW(16)}`};

  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    justify-content: space-between;
    padding: 0 ${desktopVW(60)};
  }
`

const GridItem = styled(SiteLink).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['bgColor'].includes(prop)
})`
  position: relative;
  width: ${mobileVW(163)};
  height: ${mobileVW(253)};
  background: ${({ bgColor }) =>
    bgColor === 0
      ? color.grey
      : bgColor === 1
      ? color.yellow
      : bgColor === 2
      ? color.red
      : color.blue};

  .gatsby-image-wrapper {
    width: ${mobileVW(71)};
    height: ${mobileVW(172)};
    position: absolute;
    left: 50%;
    top: ${mobileVW(45)};
    transform: translateX(-50%);
  }

  img {
    object-fit: contain !important;
  }

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVW(299)};
    height: ${desktopVW(383)};

    .gatsby-image-wrapper {
      width: ${desktopVW(82)};
      height: ${desktopVW(222)};
      top: ${desktopVW(70)};
    }
  }
`

const InfoWrapper = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['bgColor'].includes(prop)
})`
  width: 100%;
  display: flex;
  padding: 0 ${mobileVW(16)};
  justify-content: center;
  font-size: ${mobileVW(10)};
  line-height: ${mobileVW(17)};
  font-weight: normal;
  position: absolute;
  left: 50%;
  bottom: -${mobileVW(28)};
  transform: translateX(-50%);

  @media (min-width: ${desktopBreakpoint}) {
    justify-content: space-between;
    bottom: ${desktopVW(30)};
    padding: 0 ${desktopVW(30)};
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(15)};
  }
`
const Name = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    color: ${({ bgColor }) => (bgColor === 2 ? color.white : color.black)};
  }
`

const PriceWrapper = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['bgColor'].includes(prop)
})`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    padding: 0 ${desktopVW(4)};
    color: ${({ bgColor }) => (bgColor === 2 ? color.balck : color.white)};
    background: ${({ bgColor }) => (bgColor === 2 ? color.white : color.black)};
    transform: rotate(-3.17deg);
    font-weight: bold;

    span {
      position: relative;
      top: -${desktopVW(6)};
      padding-left: ${desktopVW(2)};
      font-size: ${desktopVW(6)};
      line-height: ${desktopVW(10)};
    }
  }
`

export default FourProdsGrid
