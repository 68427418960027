import React from 'react'
import styled from 'styled-components'
import ThreeProdsGrid from '../ThreeProdsGrid'
import FourProdsGrid from '../FourProdsGrid'
import { SiteLink } from '../SiteLink'
import {
  mobileVW,
  desktopVW,
  desktopBreakpoint,
  color,
  underlineMultiLines
} from '../../styles'

const ImagesGrid = ({ imagesGrid: { title, products, cta, ctaLink } }) => {
  const splitWords = title.split(' ')
  const lastWord = splitWords.pop()
  const sentence = splitWords.join(' ')
  return (
    <Wrapper>
      <Title threeProd={products.length === 3}>
        {sentence} <div>{lastWord}</div>
      </Title>
      <StyledImagesGrid className='logo-is-yellow'>
        {products.length === 3 && <ThreeProdsGrid products={products} />}

        {products.length === 4 && <FourProdsGrid products={products} />}
        <CtaWrapper threeProd={products.length === 3}>
          <Cta slug={ctaLink.link[0].slug}>{cta}</Cta>
        </CtaWrapper>
      </StyledImagesGrid>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-top: ${mobileVW(40)};

  @media (min-width: ${desktopBreakpoint}) {
    padding-top: ${desktopVW(80)};
  }
`

const Title = styled.h2.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['threeProd'].includes(prop)
})`
  position: relative;
  width: 100%;
  font-size: ${({ threeProd }) => (threeProd ? mobileVW(42) : mobileVW(40))};
  line-height: ${({ threeProd }) => (threeProd ? mobileVW(35) : mobileVW(34))};
  text-align: center;
  padding: ${({ threeProd }) =>
    threeProd
      ? `0 0 ${mobileVW(55)} 0`
      : `${mobileVW(20)} ${mobileVW(60)} ${mobileVW(50)}`};

  div {
    ${({ threeProd }) =>
      threeProd
        ? `
          display: inline-block;

          background: ${color.black};
          color: ${color.white};
          font-weight: bold;
          margin-top: ${mobileVW(1)};
          padding: ${mobileVW(16)};
          transform: rotate(1.42deg);
        `
        : `
        word-break: break-all;
        padding: 0 ${mobileVW(25)};
        `}
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${({ threeProd }) =>
      threeProd ? desktopVW(80) : desktopVW(40)};
    line-height: ${({ threeProd }) =>
      threeProd ? desktopVW(68) : desktopVW(34)};
    padding: ${({ threeProd }) =>
      threeProd
        ? `0 ${desktopVW(220)} ${desktopVW(90)}`
        : `${desktopVW(40)} ${desktopVW(220)} ${desktopVW(90)}`};

    div {
      ${({ threeProd }) =>
        threeProd
          ? `
            margin-top: ${desktopVW(8)};
            padding: ${desktopVW(16)};
          `
          : null}
    }
  }
`

const StyledImagesGrid = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`

const CtaWrapper = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['threeProd'].includes(prop)
})`
  text-align: center;
  max-width: ${mobileVW(220)};
  padding: ${({ threeProd }) =>
    threeProd ? `${mobileVW(45)} 0 ` : `${mobileVW(85)} 0 ${mobileVW(45)}`};
  margin: 0 auto;

  @media (min-width: ${desktopBreakpoint}) {
    max-width: ${desktopVW(220)};
    padding: ${desktopVW(75)} ${desktopVW(30)} ${desktopVW(25)};
  }
`

const Cta = styled(SiteLink)`
  font-size: ${mobileVW(30)};
  line-height: ${mobileVW(36)};
  font-weight: normal;

  ${underlineMultiLines}
  
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(30)};
    line-height: ${desktopVW(36)};
  }
`

export default ImagesGrid
