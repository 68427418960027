import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import Icon from './Icon'
import EmailForm from './EmailForm'
import SmoothScroll from './animation/SmoothScroll'
import useRaf from 'hooks/window/useRaf'
import { DictionaryFormContext } from '../contexts'
import { setNewsletterPopInOpen } from '../actions'
import Window from 'utils/Window'
import { getLocalStorage, setLocalStorage, NL_POP_IN_KEY } from '../utils/localStorage'
import {
  mobileVW,
  desktopVW,
  desktopBreakpoint,
  color,
  border,
  zIndex,
  desktop
} from '../styles'
import { expo, quart } from '../styles/eases'

const NewsletterPopIn = () => {
  const dictionaryForm = useContext(DictionaryFormContext)
  const { newsletterPopInOpen } = useSelector(({ visible }) => visible)
  const dispatch = useDispatch()

  const newUser = !getLocalStorage(NL_POP_IN_KEY)

  useEffect(() => {
    if (newUser) {
      setTimeout(() => dispatch(setNewsletterPopInOpen(true)), 3600)
    }
  }, [newUser])

  return (
    <>
      {newUser && (
        <StyledNewsletterPopIn visible={newsletterPopInOpen}>
          <Title>{dictionaryForm.titleNewsletterPopIn}</Title>
          <IconWrapper onClick={() => {
            dispatch(setNewsletterPopInOpen(false))
            setLocalStorage(NL_POP_IN_KEY, true)
          }}
          >
            <Icon name='hamburger' open />
          </IconWrapper>
          <StyledEmailForm className='email-form-nl-pop-in' />
        </StyledNewsletterPopIn>
      )}
    </>
  )
}

const StyledNewsletterPopIn = styled.div`
  height: ${mobileVW(266)};
  width: ${mobileVW(300)};
  background: ${color.yellow};
  border: ${border.medium};
  position: fixed;
  left: 50%;
  bottom:  ${mobileVW(30)};
  z-index: ${zIndex.medium + 1};
  padding: ${mobileVW(60)} ${mobileVW(45)};
  transform: ${({ visible }) => visible ? 'translate(-50%, 0)' : 'translate(-50%, 200%)'};
  transition: transform .7s ${({ visible }) => visible ? expo.out : quart.inOut};

  @media (min-width: ${desktopBreakpoint}) {
    position: absolute;
    height: ${desktopVW(266)};
    width: ${desktopVW(300)};
    padding: ${desktopVW(50)};
    left: auto;
    bottom: ${desktopVW(40)};
    left: ${desktopVW(60)};
    transform: ${({ visible }) => visible ? 'translate(0, 0)' : 'translate(-200%, 0)'};
  }
`

const IconWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  top: ${mobileVW(16)};
  right: ${mobileVW(16)};
  width: ${mobileVW(16)};
  height: ${mobileVW(16)};

  @media (min-width: ${desktopBreakpoint}) {
    top: ${desktopVW(20)};
    right: ${desktopVW(20)};
    width: ${desktopVW(20)};
    height: ${desktopVW(20)};
  }
`

const Title = styled.p`
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  font-size: ${mobileVW(30)};
  line-height: ${mobileVW(26)};
  padding-bottom: ${mobileVW(30)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(30)};
    line-height: ${desktopVW(26)};
    padding-bottom: ${desktopVW(30)};
  }
`

const StyledEmailForm = styled(EmailForm)`
  &.email-form-nl-pop-in {
    margin-top: ${mobileVW(30)};
    width: 100%;
    left: 50%;
    bottom: ${mobileVW(30)};
    transform: translateX(-50%);
    position: relative;

    .form-success-message {
      text-align: center;
    }

    @media (min-width: ${desktopBreakpoint}) {
      margin-top: ${desktopVW(30)};
      bottom: ${desktopVW(30)};
    }
  }
`

export default NewsletterPopIn
