import React from 'react'
import styled from 'styled-components'
import { SiteLink } from './SiteLink'
import { desktopBreakpoint } from '../styles'

const TernaryLink = ({ children, slug, className }) => {
  if (slug) {
    return (
      <StyledSiteLink className={className} slug={slug}>
        {children}
      </StyledSiteLink>
    )
  } else {
    return <>{children}</>
  }
}

const StyledSiteLink = styled(SiteLink)`
  .hide-on-mobile {
    display: none;
  }

  @media (min-width: ${desktopBreakpoint}) {
    .hide-on-mobile {
      display: flex;
    }

    .hide-on-desktop {
      display: none;
    }
  }
`

export default TernaryLink
