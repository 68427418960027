import React, { useState, useRef, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import Img from 'gatsby-image'
import TernaryLink from './TernaryLink'
import Icon from './Icon'
import FreeShipping from './FreeShipping'
import { LayoutContext } from '../contexts'
import useHeaderColor from '../hooks/useHeaderColor'
import useRootMargin from '../hooks/window/useRootMargin'
import {
  mobileVW,
  desktopVW,
  zIndex,
  color,
  desktopBreakpoint
} from '../styles'
import AnimatedElement from './animation/AnimatedElement'

const Media = ({
  className,
  media: { title, cta, link, posterDesktop, posterMobile, video, videoMp4 },
  strip = false,
  styleHero,
  marginTop,
  controlsOn
}) => {
  const videoEl = useRef()
  const { layoutStyle } = useContext(LayoutContext)
  const [ready, setReady] = useState(false)
  const rootMargin = useRootMargin()
  const [refMedia, inViewMedia] = useInView({ rootMargin })

  useHeaderColor(
    inViewMedia,
    {
      left: color.yellow,
      center: color.yellow,
      right: color.yellow
    },
    {
      left: color.black,
      center: layoutStyle.pageBg === color.yellow ? color.black : color.yellow,
      right: color.black
    }
  )

  useEffect(() => {
    const videoPlaying = () => {
      setReady(true)
      videoEl.current.removeEventListener('playing', videoPlaying)
    }

    if (videoEl.current) {
      videoEl.current.addEventListener('playing', videoPlaying)
    }

    return () => {
      if (videoEl.current) videoEl.current.removeEventListener('playing', videoPlaying)
    }
  }, [])

  return (
    <TernaryLink slug={link ? link.link[0].slug : null}>
      <StyledMedia styleHero={styleHero} marginTop={marginTop} ref={refMedia}>
        {(title || cta) && (
          <TextWrapper
            styleHero={styleHero}
            marginTop={marginTop}
            className={className}
          >
            {title && <Title>{title}</Title>}
            {cta && (
              <CTA>
                {cta}
                <Icon name='cta-arrow' />
              </CTA>
            )}
          </TextWrapper>
        )}

        {strip && (
          <FreeShipping animated hero />
        )}

        <MediaWrapper ratio={0.8}>
          {(video || videoMp4) && (
            <VideoWrapper>
              <VideoPlayer
                styleHero={styleHero}
                controls={controlsOn}
                autoPlay
                disableRemotePlayback
                loop
                playsInline
                muted
                ref={videoEl}
              >
                {video && <source src={video.file.url} type='video/webm' />}
                {videoMp4 && <source src={videoMp4.file.url} type='video/mp4' />}
              </VideoPlayer>
            </VideoWrapper>
          )}

          <ImageWrapper hide={ready}>
            <Img
              alt={posterDesktop.title}
              fluid={posterDesktop.fluid}
              className={posterMobile ? 'hide-on-mobile' : null}
              backgroundColor={styleHero === '1/2_width_hero' ? color.yellow : color.red}
            />
            {posterMobile && (
              <Img
                alt={posterMobile.title}
                fluid={posterMobile.fluid}
                className='hide-on-desktop'
              />
            )}
          </ImageWrapper>
        </MediaWrapper>
      </StyledMedia>
    </TernaryLink>
  )
}

const StyledMedia = styled.div`
  position: relative;
  width: 100%;
  height: ${({ styleHero }) => styleHero === 'Full_height_hero' ? '100%' : '80vh'};
  overflow: hidden;

  .hide-on-mobile {
    display: none;
  }

  @media (min-width: ${desktopBreakpoint}) {
    height: 100%;
    margin-top: ${({ marginTop }) => (marginTop ? desktopVW(65) : '0')};
    width: ${({ styleHero }) => styleHero === '1/2_width_hero' ? '50%' : '100%'};

    .hide-on-mobile {
      display: flex;
    }

    .hide-on-desktop {
      display: none;
    }
  }
`

const TextWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-46%);
  width: 100%;
  z-index: ${zIndex.low};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${({ styleHero }) => styleHero === '1/2_width_hero' ? '50%' : '100%'};
    display: ${({ styleHero }) => styleHero === '1/2_width_hero' ? 'none' : 'inherit'};
    transform: ${({ marginTop }) => marginTop ? 'translateY(-9%)' : 'translateY(-46%)'};

    &.hero-home {
      top: auto;
      bottom: 0;
    }
  }
`

const Title = styled.h2`
  font-size: ${mobileVW(60)};
  line-height: ${mobileVW(51)};
  word-break: break-word;
  width: 100%;
  color: ${color.yellow};
  text-align: center;
  padding: 0 0 ${mobileVW(46)};
  margin-left: -0.05em;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(60)};
    line-height: ${desktopVW(51)};
    padding: 0 ${desktopVW(420)} ${desktopVW(30)};
    margin-left: -0.04em;
  }
`

const CTA = styled.p`
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(14)};
  width: 100%;
  color: ${color.yellow};
  text-align: center;

  svg {
    position: relative;
    width: ${mobileVW(22)};
    height: ${mobileVW(22)};
    padding-left: ${mobileVW(6)};
    top: ${mobileVW(5)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(16)};
    line-height: ${desktopVW(14)};
    margin-bottom: -${desktopVW(5)};

    svg {
      width: ${desktopVW(22)};
      height: ${desktopVW(22)};
      padding-left: ${desktopVW(6)};
      top: ${desktopVW(6)};
    }
  }
`

const MediaWrapper = styled(AnimatedElement)`
  height: 100%;

  @media (min-width: ${desktopBreakpoint}) {
    min-height: ${desktopVW(900)};
  }
`

const VideoWrapper = styled.div`
  position: relative;
  height: 100%;
  padding-bottom: 65.97%;
`

const VideoPlayer = styled.video`
  object-fit: cover;
  width: ${mobileVW(1120)};
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;

  ${(controls) =>
    !controls
      ? `
      &::-webkit-media-controls {
        display: block;
        -webkit-appearance: block;
      }
    `
      : null}
      
  @media (min-width: ${desktopBreakpoint}) {
    height: ${({ styleHero }) => styleHero === 'Full_height_hero' ? '100vh' : 'auto'};
  }
      
`

const ImageWrapper = styled.div`
  visibility: ${({ hide }) => (hide ? 'hidden' : 'inherit')};
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0; 
  left: 0;

  .gatsby-image-wrapper {
    height: 100%;
  }
`

export default Media
