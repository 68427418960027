import React, { useRef, useEffect } from 'react'

import offsetTop from 'utils/offsetTop'
import useWindowResize from 'hooks/window/useWindowResize'
import useDebouncedCallback from 'hooks/useDebouncedCallback'
import useRaf from 'hooks/window/useRaf'
import SmoothScroll from './SmoothScroll'
import detectIt from 'detect-it'
import Window from 'utils/Window'

/**
 * AnimatedElement
 * @param {ratio} [0-2]
 */
const AnimatedElement = ({
  children,
  className,
  selector,
  ratio = 1,
  dx = 0,
  scale = 1,
  ease = 1,
  rotateInit = 0,
  rotate,
  origin = 'center',
  as = 'div'
}) => {
  const el = useRef()
  const target = useRef()
  const Tag = as

  if (detectIt.primaryInput !== 'touch') {
    const a = useRef(rotateInit)
    const x = useRef(0)
    const y = useRef(0)
    const ox = useRef(null)
    const oy = useRef(null)
    const top = useRef(0)
    const topMod = useRef(0)
    const height = useRef(0)
    const r = +ratio - 1
    const enabled = r || rotate || dx

    if (ratio < 0) console.warn('ratio should be included between 0 & 2')

    const handleResize = (e) => {
      // parallax
      if (target.current && enabled) {
        top.current = offsetTop(target.current)
        height.current = target.current.offsetHeight
        topMod.current =
          Window.innerHeight < height.current
            ? top.current % Window.innerHeight
            : 0
        if (origin === 'center') { topMod.current = ((topMod.current / 2 + Window.innerHeight - height.current) >> 1) - top.current } else if (origin === 'bottom') { topMod.current += Window.innerHeight - top.current }
      }
    }

    useEffect(() => {
      target.current = selector
        ? el.current.querySelector(selector)
        : el.current
      handleResize()
    }, [])
    useWindowResize(useDebouncedCallback(handleResize))

    useRaf(() => {
      if (enabled) {
        let needsUpdate = false

        // if (easing) {
        //  const sign = ((r > 0) - (r < 0)) || +r
        //  r = sign * easing(Math.abs(r))
        // }

        // y
        const initY = top.current - SmoothScroll.y
        const destY = (-SmoothScroll.y - topMod.current) * r
        y.current += (destY - y.current) * ease
        const roundedY = ((y.current * 100) | 0) / 100
        if (roundedY !== oy.current) needsUpdate = true

        const percent = initY / Window.innerHeight

        // x
        x.current += (dx * percent - x.current) * ease
        const roundedX = ((x.current * 100) | 0) / 100
        if (roundedX !== ox.current) needsUpdate = true

        // rotation
        if (rotate) {
          const destRotation = -rotate * percent + rotateInit
          a.current += (destRotation - a.current) * ease
          if (destRotation !== a.current) needsUpdate = true
        }

        // constraints
        const newY = initY + roundedY
        const isIn =
          (newY + height.current > 0 && newY < Window.innerHeight) ||
          (initY + height.current > 0 && initY < Window.innerHeight)

        if (target.current && isIn && needsUpdate) {
          const scaleTransformation = scale !== 1 ? ` scale(${scale})` : ''
          const rotateTransformation = a.current
            ? ` rotate(${a.current}deg)`
            : ''
          target.current.style.transform = `translate3d(${roundedX}px,${roundedY}px,0)${rotateTransformation}${scaleTransformation}`
        }

        oy.current = roundedY
      }
    })
  }

  return (
    <Tag ref={el} className={className}>
      {children}
    </Tag>
  )
}

export default AnimatedElement
