import React, { useContext } from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { useInView } from 'react-intersection-observer'
import { SiteLink } from './SiteLink'
import Price from './Price'
import { StoreContext } from '../contexts'
import useRootMargin from '../hooks/window/useRootMargin'
import useHeaderColor from '../hooks/useHeaderColor'
import {
  mobileVW,
  desktopVW,
  color,
  zIndex,
  desktopBreakpoint
} from '../styles'

const ThreeProdsGrid = ({ products }) => {
  const { currency } = useContext(StoreContext)
  const rootMargin = useRootMargin()
  const [refThreeImgGrid, inViewThreeImgsGrid] = useInView({ rootMargin })

  useHeaderColor(
    inViewThreeImgsGrid,
    {
      left: color.yellow,
      center: color.yellow,
      right: color.yellow
    },
    {
      left: color.black,
      center: color.yellow,
      right: color.black
    }
  )

  return (
    <>
      <Grid ref={refThreeImgGrid}>
        {products.map(
          ({ slug, productImages, title, shopifyProduct }, index) => (
            <GridItem
              key={index}
              slug={slug}
              className={index === 0 ? 'big' : index === 1 ? 'medium' : 'small'}
            >
              <Image
                fluid={productImages[0].backgroundImage.fluid}
                alt={productImages[0].backgroundImage.title}
              />
              <Image
                fluid={productImages[0].foregroundImage ? productImages[0].foregroundImage.fluid : null}
                alt={productImages[0].foregroundImage ? productImages[0].foregroundImage.title : null}
                className='product-image'
              />
              <InfoWrapper>
                <Name>{title}</Name>
                <ProductPrice>
                  <Price shopifyProduct={shopifyProduct} label />
                </ProductPrice>
              </InfoWrapper>
            </GridItem>
          )
        )}
      </Grid>
    </>
  )
}

const Grid = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: repeat(1, 1fr);
  gap: ${mobileVW(16)};
  padding: 0 ${mobileVW(16)};

  @media (min-width: ${desktopBreakpoint}) {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(9, 1fr);
    grid-column-gap: ${desktopVW(60)};
    grid-row-gap: ${desktopVW(60)};
    padding: 0 ${desktopVW(60)};
    height: ${desktopVW(1250)};
  }
`

const GridItem = styled(SiteLink)`
  width: ${mobileVW(344)};
  height: ${mobileVW(413)};
  position: relative;

  .gatsby-image-wrapper {
    height: 100%;
    position: initial;
  }

  .product-image {
    z-index: ${zIndex.low};
    width: ${mobileVW(109)};
    max-height: ${mobileVW(283)};

    &.gatsby-image-wrapper {
      position: absolute !important;
      top: ${mobileVW(65)};
      left: 50%;
      transform: translateX(-50%);
    }

    img {
      object-fit: contain !important;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    width: auto;
    height: auto;

    &.big {
      grid-area: 1 / 1 / 10 / 4;

      .product-image {
        width: ${desktopVW(246)};
        max-height: ${desktopVW(580)};

        &.gatsby-image-wrapper {
          top: ${desktopVW(212)};
        }
      }
    }

    &.medium {
      grid-area: 1 / 4 / 6 / 6;

      .product-image {
        width: ${desktopVW(162)};
        max-height: ${desktopVW(404)};

        &.gatsby-image-wrapper {
          top: ${desktopVW(60)};
        }
      }
    }

    &.small {
      grid-area: 6 / 4 / 10 / 6;

      .product-image {
        width: ${desktopVW(112)};
        max-height: ${desktopVW(354)};

        &.gatsby-image-wrapper {
          top: ${desktopVW(60)};
        }
      }
    }
  }
`

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  bottom: ${mobileVW(60)};
  padding: 0 ${mobileVW(16)};
  justify-content: space-between;
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(24)};

  @media (min-width: ${desktopBreakpoint}) {
    bottom: ${desktopVW(50)};
    padding: 0 ${desktopVW(45)};
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(15)};
  }
`
const Name = styled.div`
  color: ${color.yellow};
  max-width: 90%;
`

const ProductPrice = styled.div`
  position: absolute;
  bottom: 0;
  right: ${mobileVW(16)};
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(21)};
  background: ${color.yellow};
  transform: rotate(-3.17deg);
  padding: 0 ${mobileVW(4)};

  span {
    position: relative;
    top: -${mobileVW(4)};
    padding-left: ${mobileVW(2)};
    font-size: ${mobileVW(6)};
    line-height: ${mobileVW(10)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    bottom: 0;
    right: ${desktopVW(40)};
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(21)};
    padding: 0 ${desktopVW(4)};

    span {
      position: relative;
      top: -${desktopVW(6)};
      padding-left: ${desktopVW(2)};
      font-size: ${desktopVW(6)};
      line-height: ${desktopVW(10)};
    }
  }
`

export default ThreeProdsGrid
