import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import HeroHome from '../components/home/HeroHome'
import HomeCatchPhrase from '../components/home/HomeCatchPhrase'
import HomeImagesGrid from '../components/home/HomeImagesGrid'
import Media from '../components/Media'
import HomeTextImage from '../components/home/HomeTextImage'
import Slider from '../components/Slider'
import SocialCta from '../components/SocialCta'

const Home = ({
  data: {
    contentfulHomePage: {
      seoTitle,
      seoDescription,
      style,
      heroStrip,
      hero,
      blocks
    }
  }
}) => {
  return (
    <>
      <SEO title={seoTitle} description={seoDescription} isPage />
      <HeroHome styleHero={style} heroStrip={heroStrip} media={hero} />
      <section>
        {blocks.map((block, i) => {
          switch (block.__typename) {
            case 'ContentfulCatchPhrase':
              return <HomeCatchPhrase key={i} catchPhrase={block} />
            case 'ContentfulImagesGrid':
              return <HomeImagesGrid key={i} imagesGrid={block} />
            case 'ContentfulMedia':
              return <Media key={i} media={block} marginTop />
            case 'ContentfulTextImage':
              return <HomeTextImage key={i} textImage={block} />
            case 'ContentfulSlider':
              return <Slider key={i} slider={block} />
            case 'ContentfulSocialCta':
              return <SocialCta key={i} socialCta={block} noMarginTop />
            default:
              return null
          }
        })}
      </section>
    </>
  )
}

export const PageQuery = graphql`
  query Home($node_locale: String!) {
    contentfulHomePage(node_locale: { eq: $node_locale }) {
      seoTitle
      seoDescription
      style
      hero {
        ...media
      }
      blocks {
        __typename
        ... on ContentfulCatchPhrase {
          ...catchPhrase
        }
        ... on ContentfulImagesGrid {
          ...imagesGrid
        }
        ... on ContentfulMedia {
          ...media
        }
        ... on ContentfulSlider {
          ...slider
        }
        ... on ContentfulTextImage {
          ...textImage
        }
        ... on ContentfulSocialCta {
          ...socialCta
        }
      }
    }
  }
`

export default Home
