import React from 'react'
import styled from 'styled-components'
import Media from '../Media'
import TernaryLink from '../TernaryLink'
import NewsletterPopIn from '../NewsletterPopIn'
import { mobileVW, desktopVW, desktopBreakpoint, color } from '../../styles'

const HeroHome = ({ styleHero, media }) => {
  const currentStyle = styleHero[0].split(' ').join('_')

  return (
    <StyledHeroHome styleHero={currentStyle}>
      <StyledMedia
        media={media}
        strip
        styleHero={currentStyle}
        className='hero-home'
      />
      {currentStyle === '1/2_width_hero' && (
        <TernaryLink
          slug={media.link ? media.link.link[0].slug : null}
          className='hide-on-mobile'
        >
          <RightBox className='hide-on-mobile'>
            <TextWrapper styleHero={currentStyle} className='hide-on-mobile'>{media.title}</TextWrapper>
          </RightBox>
        </TernaryLink>
      )}
      <NewsletterPopIn />
    </StyledHeroHome>
  )
}

const StyledHeroHome = styled.section`
  position: relative;
  width: 100%;
  top: 0;
  height: ${mobileVW(694)};
  overflow: hidden;

  @media (min-width: ${desktopBreakpoint}) {
    top: ${({ styleHero }) => styleHero === '3/4_height_hero' ? desktopVW(170) : '0'};
    height: 100vh;
    ${({ styleHero }) =>
      styleHero === '1/2_width_hero' ? `min-height: ${desktopVW(900)}` : null};
    padding-bottom: ${({ styleHero }) => styleHero === '3/4_height_hero' ? desktopVW(170) : '0'};
  }
`

const StyledMedia = styled(Media)`
  @media (min-width: ${desktopBreakpoint}) {
    width: ${({ styleHero }) =>
      styleHero === '1/2_width_hero' ? '50%' : '100%'};
  }
`

const RightBox = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: ${color.red};
  }
`

const TextWrapper = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    position: absolute;
    bottom: ${desktopVW(210)};
    right: 0;
    font-size: ${desktopVW(100)};
    line-height: ${desktopVW(84)};
    color: ${color.yellow};
    padding: 0 10% 0 25%;
    word-break: break-word;
  }
`

export default HeroHome
