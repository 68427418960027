import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { useInView } from 'react-intersection-observer'
import EmailForm from './EmailForm'
import useRootMargin from '../hooks/window/useRootMargin'
import useHeaderColor from '../hooks/useHeaderColor'
import {
  mobileVW,
  desktopVW,
  color,
  border,
  zIndex,
  desktopBreakpoint
} from '../styles'
import AnimatedElement from './animation/AnimatedElement'

const SocialCta = ({
  socialCta: { newsletterTitle, cta, ctaColor, externalLink, image },
  noBorderBottom,
  noMarginTop
}) => {
  const rootMargin = useRootMargin()
  const [refSocialCta, inViewSocialCta] = useInView({ rootMargin })

  useHeaderColor(
    inViewSocialCta,
    {
      left: color.yellow,
      center: color.yellow,
      right: color.black
    },
    {
      left: color.black,
      center: color.yellow,
      right: color.black
    }
  )

  return (
    <StyledSocialCta
      ref={refSocialCta}
      noBorderBottom={noBorderBottom}
      noMarginTop={noMarginTop}
    >
      <Newsletter ratio={1.1}>
        <Title>{newsletterTitle}</Title>
        <StyledEmailForm withDem className='email-form-social-cta' />
      </Newsletter>
      <Social target='_blank' href={externalLink} rel='noopener noreferrer'>
        <ImageWrapper ratio={0.9} scale={1.05}>
          <Image fluid={image.fluid} alt={image.title} />
        </ImageWrapper>
        <Cta fontColor={ctaColor.hexCode}>{cta}</Cta>
      </Social>
    </StyledSocialCta>
  )
}

const StyledSocialCta = styled.div`
  position: relative;
  width: 100%;
  margin-top: ${({ noMarginTop }) => noMarginTop ? '0' : mobileVW(25)};
  padding-top: ${mobileVW(80)};
  overflow: hidden;
  border-top: ${border.medium};

  @media (min-width: ${desktopBreakpoint}) {
    padding-top: 0;
    display: flex;
    margin-top: ${({ noMarginTop }) => noMarginTop ? '0' : desktopVW(55)};
    border-bottom: ${({ noBorderBottom }) =>
      noBorderBottom ? null : border.medium};
  }
`

const Newsletter = styled(AnimatedElement)`
  position: relative;
  width: 100%;

  @media (min-width: ${desktopBreakpoint}) {
    order: 2;
  }
`

const Title = styled.h2`
  position: relative;
  width: 100%;
  font-size: ${mobileVW(43)};
  line-height: ${mobileVW(38)};
  text-align: center;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(70)};
    line-height: ${desktopVW(62)};
    top: 50%;
    transform: translateY(-63%);
    padding: 0 ${desktopVW(120)};
  }
`

const StyledEmailForm = styled(EmailForm)`
  &.email-form-social-cta {
    margin: ${mobileVW(20)} ${mobileVW(60)} ${mobileVW(60)};
    margin-bottom: ${({ email }) => (email ? mobileVW(120) : mobileVW(60))};
    width: calc(100% - ${mobileVW(120)});
    position: relative;
  
    @media (min-width: ${desktopBreakpoint}) {
      position: relative;
      margin: auto;
      top: ${desktopVW(285)};
      width: ${desktopVW(245)};
      z-index: ${zIndex.medium};
    }
  }
`

const Social = styled.a`
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;

  @media (min-width: ${desktopBreakpoint}) {
    order: 1;
  }
`

const ImageWrapper = styled(AnimatedElement)`
  width: ${mobileVW(375)};
  height: ${mobileVW(375)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVW(720)};
    height: ${desktopVW(720)};
  }
`

const Cta = styled.p`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${mobileVW(30)};
  line-height: ${mobileVW(31)};
  font-weight: normal;
  text-align: center;
  text-decoration-line: underline;
  color: ${({ fontColor }) => fontColor || color.white};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(30)};
    line-height: ${desktopVW(31)};
    padding: 0 ${desktopVW(220)};
  }
`

export default SocialCta
