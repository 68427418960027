import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import Icon from '../Icon'
import { mobileVW, desktopVW, desktopBreakpoint, color } from '../../styles'
import AnimatedElement from 'components/animation/AnimatedElement'

const HomeCatchPhrase = ({
  catchPhrase: { titleFirstPart, titleSecondPart, image }
}) => (
  <StyledCatchPhrase>
    <TitleFirstPart titleSecondPart={titleSecondPart}>
      {titleFirstPart}
    </TitleFirstPart>
    <ImageWrapper ratio={1.15} rotate={2} rotateInit={-3}>
      <Image fluid={image.fluid} alt={image.title} />
    </ImageWrapper>
    <TitleSecondPart>{titleSecondPart}</TitleSecondPart>
    <IconWrapper ratio={1.3} rotate={22} rotateInit={10}>
      <Icon name='logo-monogram' />
    </IconWrapper>
  </StyledCatchPhrase>
)

const StyledCatchPhrase = styled.div`
  position: relative;
  height: ${mobileVW(652)};
  padding-top: ${mobileVW(60)};

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVW(1300)};
    padding-top: ${desktopVW(180)};
  }
`

const TitleFirstPart = styled.h2`
  position: relative;
  width: 100%;
  font-size: ${mobileVW(56)};
  line-height: ${mobileVW(47)};
  text-align: center;
  padding: ${mobileVW(20)} ${mobileVW(20)};
  color: ${color.white};
  -webkit-text-stroke: 0.5px ${color.black};

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0;
    font-size: ${desktopVW(200)};
    line-height: ${desktopVW(169)};
  }
`

const ImageWrapper = styled(AnimatedElement)`
  position: absolute;
  top: ${mobileVW(148)};
  left: 50%;
  transform: rotate(-2deg);
  height: ${mobileVW(399)};
  width: ${mobileVW(279)};
  margin-left: ${mobileVW(-140)};

  @media (min-width: ${desktopBreakpoint}) {
    top: ${desktopVW(460)};
    height: ${desktopVW(451)};
    width: ${desktopVW(316)};
    margin-left: ${desktopVW(-158)}
  }
`

const IconWrapper = styled(AnimatedElement)`
  position: absolute;
  right: ${mobileVW(139)};
  bottom: ${mobileVW(40)};

  svg {
    height: ${mobileVW(52)};
    width: ${mobileVW(52)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    right: ${desktopVW(555)};
    bottom: ${desktopVW(145)};

    svg {
      height: ${desktopVW(180)};
      width: ${desktopVW(180)};
    }
  }
`

const TitleSecondPart = styled(TitleFirstPart)`
  position: absolute;
  color: ${color.black};
  -webkit-text-stroke: none;
  bottom: ${mobileVW(25)};

  @media (min-width: ${desktopBreakpoint}) {
    bottom: ${desktopVW(145)};
    padding: 0 ${desktopVW(200)};
  }
`

export default HomeCatchPhrase
