import React, { useRef } from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { useInView } from 'react-intersection-observer'
import { SiteLink } from '../SiteLink'
import RichText from '../RichText'
import Icon from '../Icon'
import useRootMargin from '../../hooks/window/useRootMargin'
import useHeaderColor from '../../hooks/useHeaderColor'
import { mobileVW, desktopVW, color, desktopBreakpoint } from '../../styles'
import AnimatedElement from 'components/animation/AnimatedElement'

const TextImage = ({ textImage: { title, body, image, cta, link } }) => {
  const rootMargin = useRootMargin()
  const ref = useRef()
  const [refImgTextHome, inViewImgTextHome] = useInView({ rootMargin })

  useHeaderColor(
    inViewImgTextHome,
    {
      left: color.yellow,
      center: color.yellow,
      right: color.yellow
    },
    {
      left: color.black,
      center: color.yellow,
      right: color.black
    }
  )

  return (
    <StyledTextImage ref={ref}>
      <IconWrapper ref={refImgTextHome}>
        <Banner className='logo-strip-down'>
          <Icon use name='logo' />
          <Icon use name='logo' />
          <Icon use name='logo' />
        </Banner>
        <Banner className='logo-strip-up'>
          <Icon use name='logo' />
          <Icon use name='logo' />
          <Icon use name='logo' />
        </Banner>
      </IconWrapper>
      <ImageWrapper rotateInit={3} rotate={-2} dx={10}>
        <Image fluid={image.fluid} alt={image.title} />
      </ImageWrapper>

      <TextWrapper rotateInit={-1} rotate={4} dx={-40}>
        <Title>{title}</Title>
        <RichText json={JSON.parse(body.body)} />
        {cta && link && (
          <Cta slug={link.link[0].slug}>
            {cta}
            <Icon name='cta-arrow' />
          </Cta>
        )}
      </TextWrapper>
    </StyledTextImage>
  )
}

const StyledTextImage = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${mobileVW(45)};
  margin-bottom: -${mobileVW(45)};
  overflow: hidden;

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVW(50)} 0 ${desktopVW(15)};
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const IconWrapper = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['bgColor'].includes(prop)
})`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    width: 100%;
    position: absolute;
    display: block;
    height: ${desktopVW(516)};
  }
`

const Banner = styled.div`
  position: absolute;
  left: 0;
  width: 106%;
  display: flex;

  svg {
    flex-shrink: 0;
    width: ${mobileVW(1331)};
    height: ${mobileVW(160)};
    animation-name: translate;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @media (min-width: ${desktopBreakpoint}) {
      width: ${desktopVW(1331)};
      height: ${desktopVW(160)};
      padding-right: ${desktopVW(24)};
    }
  }

  &:first-child {
    top: 52%;
    padding: ${desktopVW(40)} 0;
    margin-top: -${desktopVW(10)};
    transform: rotate(0.98deg);
    background: ${color.blue};
    fill: ${color.black};
  }

  &:last-child {
    top: 8%;
    padding: ${desktopVW(40)} 0;
    margin-top: ${desktopVW(10)};
    margin-left: -${desktopVW(30)};
    transform: rotate(-4.74deg);
    background: ${color.black};
    fill: ${color.yellow};

    svg {
      animation-direction: reverse;
    }
  }
`

const TextWrapper = styled(AnimatedElement).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['bgColor'].includes(prop)
})`
  position: relative;
  display; flex;
  flex-direction: column;
  width: ${mobileVW(398)};
  padding: ${mobileVW(60)} ${mobileVW(140)} ${mobileVW(60)} ${mobileVW(60)};
  background: ${color.red};
  color: ${color.white};
  transform: translate(13%,-16%) rotate(-3.39deg);

  p {
    text-transform: initial;
    font-size: ${mobileVW(16)};
    line-height: ${mobileVW(26)};
    padding: ${mobileVW(16)} ${mobileVW(10)} ${mobileVW(16)} 0;
  }

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVW(582)};
    padding: ${desktopVW(120)} ${desktopVW(140)} ${desktopVW(120)} ${desktopVW(120)};
    margin-left: ${desktopVW(-40)};
    // transform: translate(-3%, -3%) rotate(-3.39deg);
    transform-origin: 50% 90%;
    justify-content: center;

    p {
      font-size: ${desktopVW(16)};
      line-height: ${desktopVW(26)};
      padding: ${desktopVW(20)} ${desktopVW(30)} ${desktopVW(30)} 0;
    }
  }
`

const Title = styled.h2`
  position: relative;
  font-size: ${mobileVW(25)};
  line-height: ${mobileVW(21)};
  padding-right: ${mobileVW(21)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(40)};
    line-height: ${desktopVW(34)};
    padding-right: ${desktopVW(21)};
    margin-top: ${desktopVW(0)};
  }
`

const ImageWrapper = styled(AnimatedElement)`
  height: 100%;
  height: ${mobileVW(366)};
  width: ${mobileVW(245)};
  transform: rotate(3deg);
  position: relative;
  left: -${mobileVW(10)};

  .gatsby-image-wrapper {
    height: 100%;
  }

  @media (min-width: ${desktopBreakpoint}) {
    height: 100%;
    width: ${desktopVW(534)};
    left: 0;
    /* transform: translateX(19%) rotate(3deg); */
    transform-origin: 80% 90%;
  }
`

const Cta = styled(SiteLink).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['bgColor'].includes(prop)
})`
  display: inline-block;
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(14)};
  font-weight: bold;
  color: ${color.white};

  svg {
    position: relative;
    width: ${mobileVW(22)};
    height: ${mobileVW(22)};
    padding-left: ${mobileVW(6)};
    top: ${mobileVW(5)};

    circle {
      fill: ${color.white};
    }

    path {
      fill: ${color.black};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(16)};
    line-height: ${desktopVW(20)};

    svg {
      width: ${desktopVW(22)};
      height: ${desktopVW(22)};
      padding-left: ${desktopVW(6)};
      top: ${desktopVW(6)};
    }
  }
`

export default TextImage
